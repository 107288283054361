@import "../../../public/mixins/mixins.scss";

.appBannerWrapper {
  max-width: 1440px;
  margin: auto;
  padding: 100px 78px;
  @include mobile() {
    padding: 20px;
  }
  .appBannerContainer {
    @include flexContainer(row, unset, stretch, 0, 0);
    border-radius: 40px;
    background: var(
      --splash-gradient,
      linear-gradient(180deg, #3865ff 0%, #70c6e7 100%)
    );
    position: relative;
    @include mobile() {
      border-radius: 10px;
    }

    .infoContainer {
      padding: 70px 128px;
      width: 75%;
      @include flexContainer(column, unset, unset, 40px, 0px);
      @include mobile() {
        padding: 35px 20px 160px 20px;
        row-gap: 15px;
      }
      .heading {
        @include font(32px, 600, #fff, 1.28px);
        @include mobile() {
          font-size: 20px;
        }
        margin-bottom: auto;
      }
      .subheading {
        @include font(18px, 400, #fff, 0.72px);
        @include mobile() {
          font-size: 14px;
        }
      }
      .iconContainer {
        @include flexContainer(row, unset, unset, 10px, 34px);
        @include mobile() {
          flex-direction: column;
        }

        .storeIcons {
          width: 100%;
          height: auto;
          @include mobile() {
            width: 120px;
          }
        }
      }
    }

    .mobile {
      width: 400px;
      height: auto;
      position: absolute;
      right: 50px;
      bottom: -40px;
      @include mobile() {
        width: 220px;
        right: 10px;
      }
    }
  }
}
